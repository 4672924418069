if (!window.vimas)
    var vimas = {};

if (!window.vimas.sco)
    vimas.sco = {};

vimas.xml = new function () {

    var $this = $(this);

    this.getXHR = function () {

        var xhr = new XMLHttpRequest();

        if ("withCredentials" in xhr)
            return xhr;
        else
            return new XDomainRequest();
    }

    this.load = function (url, func) {

        var xhr = this.getXHR();

        xhr.onload = function () {
            func(xhr);
        }

        xhr.open("get", url, true);
        xhr.send();
    }

    this.loadXML = function (url, func, context) {

        $.get(url, { dataType: "xml" }).then(func);

        return $this;
    }

    this.loadText = function (url, func, context) {

        this.load(url,
            function (xhr) {

                if (func)
                    func.call(context, xhr.responseText);               
            });
    }

    this.parseString = function (data) {

        var xml;
        if (window.DOMParser) {
            var parser = new window.DOMParser();
            xml = parser.parseFromString(data, "text/xml");

        } else {
            xml = new ActiveXObject("Microsoft.XMLDOM");
            xml.async = false;
            xml.loadXML(data);
        }

        return xml;
    }

    this.getElementText = function (root, element) {

        var node;

        if (element != null)
            node = root.getElementsByTagName(element)[0];
        else
            node = root;

        if (node.childNodes.length > 0)
            return node.childNodes[0].nodeValue;
        else
            return null;
    }

    this.DOMtoString = function (dom) {
        if (dom.xml) {
            return dom.xml;
        } else if (window.XMLSerializer) {
            return (new XMLSerializer()).serializeToString(dom);
        } else {
            return dom.outerHTML;
        }
    }

    this.xpath = function (expression, root) {
        if (document.evaluate) {
            return document.evaluate(expression, root, null, XPathResult.ANY_TYPE, null)
        } else {
            var doc = new ActiveXObject("Msxml2.DOMDocument.6.0");
            doc.loadXML(root);
            return doc.selectNodes(expression);
        }
    }
};

vimas.loader = new function () {

    var context = this;
    var params = {};    

    function vmPreload(data) {

        // prepare localization and html storage variables
        var _localization = [];
        var _html = {};

        // create preload deferred
        var preload = $.Deferred();     

        // if idevice scripts were not loaded
        // than load them
        var scripts = null
        if (!Idevice.scripts_key) {
            
            scripts = vmLoadScript('script/idevice.min.js', 'vimas.loader', 'scripts');
        };

        // if required action doesn't match
        // to the one already loaded
        // then clear previous resources
        // unsubscribe action events
        var action = null;
        if (data.skin.action !== Idevice.action_key) {

            Idevice.cleanup('action');
            $(Idevice).off();

            action = vmLoadScript('action/{0}/{0}.min.js'.format(data.skin.action), 'vimas.loader', 'action');
        };

        // if required skin doesn't match
        // to the one already loaded
        // than clear previous one resources
        // and load new
        var css = null;
        if (data.skin.skin !== Idevice.css_key) {

            Idevice.cleanup('css');
            css = vmLoadCss('css/{0}/{0}.min.css'.format(data.skin.skin), 'vimas.loader');
        };        

        // check if localizations and templates
        // were already loaded
        // if yes than just assign them back from Idevice object
        // if no than load from storage
        var localizations, templates;
        if (data.skin.localization === Idevice.localization_key && data.skin.html === Idevice.templates_key) {

            localizations = Idevice.localizations;
            templates = Idevice.templates;

        } else {

            localizations = vmLoadLocalizationBundle('localization/{0}/{0}.xml'.format(data.skin.localization));
            templates = vmLoadHtmlBundle('html/{0}/{0}.html'.format(data.skin.html));
        };

        // deferred for minimal timeout
        // required in case all skin parameters match
        // to wait till angular populates all templates
        var min = $.Deferred();
        setTimeout(min.resolve, 0);

        $.when(localizations, templates, scripts, action, css, min.promise())
            .then(function (localizations, templates) {

                // save to global Idevice object
                // which actions and skins were loaded
                // so that repetitive load can be skipped
                Idevice.scripts_key = true;
                Idevice.action_key = data.skin.action;
                Idevice.css_key = data.skin.skin;

                // assign localizations and templates to Idevice object
                // this is requried for activties
                // and can be reused on next load
                Idevice.localization_key = data.skin.localization;
                Idevice.localizations = localizations;
                Idevice.templates_key = data.skin.html;
                Idevice.templates = templates;

                // take localization by user language
                // if not found - take english one
                var localization = localizations[data.lang] || localizations['en'];
                Idevice.localization = localization;

                // import html templates
                for (var key in templates) {
                    vmImportHtml(key, templates);
                };

                data.scos.forEach(function (sco) {

                    var key = window[sco.type][data.mode || 'sco'].template;
                    var template = templates[key];

                    if (!key)
                        console.error('> missing template for ' + sco.type);
                    
                    var localized = vmLocalizeHtml(template, localization);
                    $(sco.container).html(localized)
                        .addClass('vms_idevice');
                });

                preload.resolve();
            });

        return preload.promise();

        function vmLoadScript(key, invoker, type) {

            var d = $.Deferred();

            var url = '{0}/{1}'.format(data.bucket, key);
            $('<script />').appendTo('head')                   
                   .attr('src', url)
                   .data('invoker', invoker)
                   .data('type', type)
                   .on('load', function () { d.resolve(); })

            return d.promise();
        };

        function vmLoadCss(key, invoker) {

            var d = $.Deferred();

            var url = '{0}/{1}'.format(data.bucket, key);
            $('<link />', { rel: 'stylesheet', href: url })
                .data('invoker', invoker)
                .data('type', 'css')
                .appendTo($('head'))
                .on('load', () => d.resolve())

            return d.promise();
        };
        
        function vmLoadHtmlBundle(file) {

            var d = $.Deferred();
            
            var url = '{0}/{1}'.format(data.bucket, file);

            $.ajax({
                type: 'GET',
                url: url,
                dataType: 'text'
            })
            .then(function (html) {

                var templates = $(html).toArray().reduce(function (p, node) {

                    p[$(node).attr('name')] = node.innerHTML || node.textContent
                    return p;

                }, {});

                d.resolve(templates);

            });

            return d.promise();
        };

        function vmLoadLocalizationBundle(file) {

            var d = $.Deferred();

            var url = '{0}/{1}'.format(data.bucket, file);
            vimas.xml.loadXML(url, function (xml) {

                var localizations = $(xml).find('localization')
                                        .toArray()
                                        .map(x => $(x).attr('type'))
                                        .unique()
                                        .reduce((p, n) => { p[n] = {}; return p; }, {});               

                $(xml).find('localization').each(function (int, local) {

                    var key = $(local).attr('type');

                    $(local)
                        .children()
                        .each(function (i, node) {

                            localizations[key][node.tagName] = node.innerHTML || node.textContent;
                        });
                });

                d.resolve(localizations);
            });

            return d.promise();
        };

        function vmLocalizeHtml(html, localization) {

            return html.replace(/\{[A-z_]+\}/g, function (exp) {

                // remove braces from tag and search for corresponding localization
                // if not found return localization tag
                var p = exp.slice(1, -1);
                if (localization[p] != 'undefined')
                    return localization[p];
                else
                    return exp;
            });
        };

        function vmImportHtml(key, templates) {

            // find specific import keys {@filename}
            // and replace them with coresponding template
            templates[key] = templates[key].replace(/\{\@[A-z_]+\}/g, function (exp) {

                var p = exp.slice(2, -1);
                return templates[p];
            });

            // if deeper level import keys are present
            // than repeat procedure
            var hasImport = templates[key].match(/\{\@[A-z_]+\}/g) != null;
            if (hasImport)               
                vmImportHtml(key, templates);
        };
    };

    this.preload = vmPreload;    
};

window.Idevice = new function () {

    var _this = this;

    // create sco object
    this.create = function (config) { };

    // post init already created
    this.postinit = function (idevice_placeholder, config, data) {

        // update localization
        config.localization = Idevice.localization;

        // create sco object
        // for required type and mode
        // and trigger created        
        var sco = window[config.idevice_type][config.mode || 'sco'].create(config, data);
        $(_this).trigger('created', sco);

        // assign root node, localization
        // and construct
        sco.vpRoot = idevice_placeholder;
        sco.construct();

        // trigger ready
        $(_this).trigger('ready', sco);

        return sco;
    };

    // clean used recources
    this.cleanup = function (type) {

        // get all script and link elements
        // with invoker 'vimas.loader'
        // and remove them
        $('script, link').filter(function () {
            return $(this).data('invoker') === 'vimas.loader' && $(this).data('type') === type;
        }).remove();
    };

    this.cache = {};
};