// string format
String.prototype.format = function () {
    var args = arguments;
    return this.replace(/{(\d+)}/g, function (match, number) {
        return typeof args[number] != 'undefined'
      ? args[number]
      : match
        ;
    });
};

// get first item from array
// by key/value
Array.prototype.first = function (key, value) {

    return this.filter(function (el) {
        return el[key] == value;
    })[0];
};

// returns element which satisfies predicate condition
if (!Array.prototype.find) {
    Array.prototype.find = function (predicate) {
        if (this == null) {
            throw new TypeError('Array.prototype.find called on null or undefined');
        }
        if (typeof predicate !== 'function') {
            throw new TypeError('predicate must be a function');
        }
        var list = Object(this);
        var length = list.length >>> 0;
        var thisArg = arguments[1];
        var value;

        for (var i = 0; i < length; i++) {
            value = list[i];
            if (predicate.call(thisArg, value, i, list)) {
                return value;
            }
        }
        return undefined;
    };
};

// returns index of element which satisfies predicate condition
if (!Array.prototype.findIndex) {
    Array.prototype.findIndex = function (predicate) {
        if (this == null) {
            throw new TypeError('Array.prototype.findIndex called on null or undefined');
        }
        if (typeof predicate !== 'function') {
            throw new TypeError('predicate must be a function');
        }
        var list = Object(this);
        var length = list.length >>> 0;
        var thisArg = arguments[1];
        var value;

        for (var i = 0; i < length; i++) {
            value = list[i];
            if (predicate.call(thisArg, value, i, list)) {
                return i;
            }
        }
        return -1;
    };
};

Array.prototype.contains = function (item) {

    if (this.indexOf(item) > -1)
        return true;
    else
        return false;
};

// get unique elements from array
Array.prototype.unique = function (selector) {

    var u = {}, a = [];
    for (var i = 0, l = this.length; i < l; ++i) {

        var key = selector ? selector(this[i]) : this[i];

        if (u.hasOwnProperty(key))
            continue;

        a.push(this[i]);
        u[key] = 1;
    };
    return a;
};

// shuffle elements list
Array.prototype.shuffle = function (b) {

    var array = this;
    for (var i = array.length - 1; i > 0; i--) {

        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    };

    return array;
};

function isEmptyObject(obj) {
    for (var i in obj) {
        return false;
    };
    return true;
};

Date.prototype.getWeek = function () {
    var onejan = new Date(this.getFullYear(), 0, 1);
    return Math.ceil((((this - onejan) / 86400000) + onejan.getDay() + 1) / 7);
};

// add full screen request / exit functions
Element.prototype.requestFullscreen = Element.prototype.requestFullscreen || Element.prototype.mozRequestFullScreen || Element.prototype.webkitRequestFullscreen;
document.exitFullScreen = document.exitFullscreen || document.mozCancelFullScreen || document.webkitExitFullscreen;

if (!String.prototype.includes) {
    String.prototype.includes = function () {
        'use strict';
        return String.prototype.indexOf.apply(this, arguments) !== -1;
    };
};

String.prototype.ToTime = function () {

    var data = this.valueOf();
    if (data == null || data == '')
        return 0;

    var regex = /[0-9]{2}/ig;

    var digits = [];
    while (true) {

        var digit = regex.exec(data);
        if (digit == null)
            break;

        digits.push(parseInt(digit[0]));
    }

    digits = digits.reverse();

    var time = 0;
    for (var i = 0; i < digits.length; i++)
        if (digits[i] != null) {

            time += digits[i] * Math.pow(60, i);
        }

    return time * 1000;
};

Number.prototype.ToTimeString = function (format) {

    var time = this.valueOf() / 1000;

    var seconds = time % 60;
    time = (time - seconds) / 60;

    var minutes = time % 60;
    time = (time - minutes) / 60;

    var hours = time % 24;

    seconds = (seconds < 10) ? '0' + seconds : seconds;
    minutes = (minutes < 10) ? '0' + minutes : minutes;
    hours = (hours < 10) ? '0' + hours : hours;

    switch (format) {
        case 'mm:ss':
            return '{1}:{0}'.format(seconds, minutes);
        default:
            return '{2}:{1}:{0}'.format(seconds, minutes, hours);
    };
};